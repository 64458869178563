<template>
  <div style="background-color: var(--v-backgroundChat-base);" :class="{ 'shadow-bottom': shadow }">
    <h4 class="pl-4 py-3 primary--text" style="font-weight: 600">
      <slot></slot>
    </h4>
    <v-progress-linear
      v-if="loading"
      indeterminate
      class="progressLoading"
    ></v-progress-linear>
    <v-divider />
  </div>
</template>
<script>
export default {
  name: 'ChatTitle',
  props: {
    title: String,
    loading: Boolean,
    shadow: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style>
.progressLoading {
  position: absolute;
  z-index: 3;
}
.title-shadow {
  box-shadow: 0 5px 7px 0px rgb(0 0 0 / 10%)
}
</style>
